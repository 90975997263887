$ag-background-color: #212121;
$ag-alt-background: #2b2b2b !default;
$ag-header-background-color: #212121 !default;

@use "~ag-grid-community/styles" as ag;
@include ag.grid-styles(
  (
    theme: balham-dark,
    --ag-font-family: Roboto
  )
);

.ag-header-cell-label,
.ag-cell {
  font-size: 14px;
  font-weight: normal;
}
