$ag-background-color: white;
$ag-alt-background: #f2f2f2 !default;
$ag-header-background-color: #f2f2f2 !default;

@use "~ag-grid-community/styles" as ag;
@include ag.grid-styles(
  (
    theme: balham,
    --ag-font-family: Roboto
  )
);

.ag-header-cell-label,
.ag-cell {
  font-size: 14px;
  font-weight: normal;
}
