/*****************************
Pagewide
*****************************/

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Black.ttf') format('truetype');
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 900;
  font-style: italic;
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print {
  #non-printable {
    display: none;
  }

  #printable {
    display: block;
  }

  html,
  body {
    height: 100% !important;
  }

  // hides placeholder text in Firefox
  .no-print {
    visibility: 'hidden';
  }

  // sets height of the notes metadata for AHA pdf print
  @supports (-moz-appearance: auto) {
    .firefoxNotesHeight {
      height: 128px !important;
    }
  }

  @page {
    size: letter landscape;
    margin: 24px;
  }
}

/*****************************
MUI Theme Overrides
*****************************/
.MuiDrawer-paperAnchorDockedRight {
  margin-top: 64px !important;
  height: calc(100vh - 64px) !important;
  @media screen and (max-width: 600px) {
    margin-top: 56px !important;
    height: calc(100vh - 56px) !important;
  }
}

label + .MuiInput-formControl {
  margin: 0 !important;
  margin-top: 10px !important;
}

.MuiBox-root,
.MuiList-root {
  padding: 0 !important;
}

.MuiInput-input,
.css-1uccc91-singleValue {
  font: unset !important;
  font-family: 'Roboto', 'ui-sans-serif', 'sans-serif' !important;
}

.MuiTableCell-root {
  padding: 6px !important;
  @media screen and (max-width: 960px) {
    padding: 8px !important;
  }
}

.MuiTab-root {
  text-transform: capitalize !important;
}

.MuiStepper-root {
  padding-top: 0 !important;
  padding-bottom: 24px !important;
}

.MuiDialogTitle-root {
  padding-bottom: 0 !important;
}

.MuiPickersToolbarText-toolbarTxt {
  padding-left: 24px !important;
}

.MuiChip-root {
  font-size: 12px !important;
}

/*****************************
Custom 
*****************************/

/* General */
.inline {
  display: inline !important;
}

.noPadding {
  padding: 0 !important;
}

.padding {
  padding: 24px !important;
}

.margin {
  margin: 24px !important;
}
.margin-top {
  margin-top: 24px !important;
}
.margin-right {
  margin-right: 24px !important;
}
.margin-bottom {
  margin-bottom: 24px !important;
}
.margin-left {
  margin-left: 24px !important;
}

/* font-size needs "!important" to override body font-size (16px) */
.bold {
  font-weight: bold !important;
}

.section-title {
  font-size: 1.25rem !important; // 20px
}
