/*****************************
React Big Calendar
*****************************/
@import 'react-big-calendar/lib/sass/styles';

.soteria-calendar-light {
  .rbc-toolbar  {
    margin: 0 auto !important;    
    margin-bottom: 16px !important;
    @media screen and (max-width: 600px) {
      display: block !important;
    }
  }

  .rbc-header {
    padding-top: 4px
  }

  .rbc-toolbar {
    flex-wrap: nowrap !important;
  }

  .rbc-toolbar .rbc-toolbar-label {
    font-size: x-large;
    @media screen and (max-width: 600px) {
      display: block !important;
    }
  }

  .rbc-toolbar-label {
    @media screen and (max-width: 600px) {
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }

  .rbc-time-view,
  .rbc-month-view {
    @media screen and (max-width: 600px) {
      margin-top: 1rem !important;
    }
  }

  .rbc-toolbar button {
    color: black !important;
  }

  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    color: black !important;
  }

  .rbc-event {
    background-color: #a6192e;
    @media screen and (max-width: 600px) {
      min-width: 40px;
    }
  }

  .rbc-today {
    background-color: #f2f2f2;
  }

  .rbc-event-label {
    display: none !important
  }

  .rbc-allday-cell {
    display: none !important
  }
}

